@import (reference) "@/client/assets/vars.less";
@import (reference) "@client/assets/css/theme.less";
@import (reference) "@/client/assets/theme.less";

@mobileLeftbarWidth: 5rem;

.mobileMenuBtn {
    position: fixed !important;
    right: 1em;
    bottom: 1em;
    box-shadow: 0 10px 50px fade(black, 30%);
    z-index: 100;
}

/* 
   .ShowDesktop => show only on screens ≥ 1025px 
   (hide on screens <= 1200px)
*/
.Desktop {
    @media (max-width: 1200px) {
        display: none !important;
    }
}
.Tablet {
    @media (max-width: 650px) {
        display: none !important;
    }

    @media (min-width: 1200px) {
        display: none !important;
    }
}

.Mobile {
    @media (min-width: 650px) {
        display: none !important;
    }
}

.HideDesktop {
    @media (min-width: 1200px) {
        display: none !important;
    }
}

.HideTablet {
    @media (min-width: 650px) and (max-width: 1200px) {
        display: none !important;
    }
}

.HideMobile {
    @media (max-width: 650px) {
        display: none !important;
    }
}
  

// [Tablet]
// Min menu bar
@media only screen and (max-width: 1200px) {
    
    #layout {

        #contPage {
            padding-left: @mobileLeftbarWidth + @spacing;
        }

        #leftbar {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            height: 100vh;
            z-index: 11;
            background: #fff;
        }
    }

    #layout:not(.MenuOpen) { 

        #Logo .LogoText {
            display: none;
        }
        
        #leftbar {
            flex: 0 0 @mobileLeftbarWidth;
            width: @mobileLeftbarWidth;
        }

        #leftbar > nav {

            gap: 2rem;

            > strong {
                display: none;
            }

            // Hide menu labels
            .btn {
                justify-content: center;

                .label {
                    display: none;
                    // position: absolute;
                    // left: 100%;
                    // background: fade(#000, 90%);
                }
            }
        }
    }

    #layout.MenuOpen {
        #leftbar {

            box-shadow: 0 50px 50px fade(#000, 10%);
            overflow: hidden; // When menu bar hidden
            
            nav .menu {
                width: @leftbarWidth;
            }
        }

        #contPage {
            filter: blur(5px);
        }
    }
}

// [Mobile]
@media only screen and (max-width: 650px) {
    
    .Mobile {
        visibility: visible !important;
    }

    #layout:not(.MenuOpen) { 

        #contPage {
            padding-left: 0;
        }
        
        #leftbar {
            flex: 0;
            width: 0;
        }
    }
}