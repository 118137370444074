/*----------------------------------
- GLOBAL THEMES
----------------------------------*/

@import './fonts/Inter/index.less';
@import './fonts/Poppins/index.less';

::selection {
    background: rgba(255,218,121,0.5);
    color: #0d0c22;
}

h1, .h1 {
    font-family: "Poppins";
    font-weight: 800;
}

h2, .h2 {
    font-family: "Poppins";
    font-weight: 800;
    color: var(--cTxtBase);
}

.btn {
    font-weight: 400;

    &.primary {
        font-weight: 500;
    }
}

// Floating
.card.popover,
.floating,
.Domains .card:hover,
.input.text.focus {
    box-shadow: 0 10px 50px fade(black, 8%);
    z-index: 10;
}

.reading a {
    text-decoration-color: var(--cTxtDiscret);
    text-decoration-thickness: 2px;
}

/*----------------------------------
- BASIC THEMES
----------------------------------*/

@whiteTheme: {
    alpha: false;
    background: #FFF;
    foreground: #555;
    accent1: @c1;
    accent2: @c2;
}

@greyTheme: {
    alpha: false;
    background: #F3F3F3;
    foreground: #4D4D4D;
    accent1: @c1;
    accent2: @c2;
}

@silverTheme: {
    alpha: false;
    background: @cBgPage - #080808;
    foreground: #777;
    accent1: @c1;
    accent2: @c2;
}

@darkTheme: {
    alpha: false;
    background: #000;
    foreground: #DDD;
    accent1: #83B2FF;
    accent2: @c2;
}

@darkerTheme: {
    alpha: false;
    background: #181818;
    foreground: #bbb;
    accent1: #fff;
    accent2: @c2;
}

@darkestTheme: {
    alpha: false;
    background: #242424;
    foreground: #ddd;
    accent1: #fff;
    accent2: @c2;
}

.bg.grey {
    .apply-theme(@greyTheme, @whiteTheme);
}

.card,
.bg.white {
    .apply-theme(@whiteTheme);
}

.bg.silver {
    .apply-theme(@silverTheme, @whiteTheme);

    box-shadow: inset 0 0 20px fade(#000, 5%)
}

.bg.dark {
    .apply-theme(@darkTheme, @darkerTheme);
}

.bg.darker {
    .apply-theme(@darkerTheme, @darkestTheme);
}

/*----------------------------------
- COLOR THEMES
----------------------------------*/

@primaryTheme: {
    alpha: false;
    background: #111;
    foreground: #fff;
    accent1: #FFF;
    accent2: #FFF;
}

@secondaryTheme: {
    alpha: false;
    background: #F5F5F5;
    foreground: #8E8E8E;
    accent1: @c1;
    accent2: @c2;
}

@accentTheme: {
    alpha: false;
    background: @c1;
    foreground: #fff;
    accent1: #fff;
    accent2: #fff;;
}

.bg.primary {

    .apply-theme(@primaryTheme);
}

.bg.secondary {
    .apply-theme(@secondaryTheme);
}

.bg.accent {
    .apply-theme(@accentTheme); 
}

.color-theme( @color ) {
    .apply-theme({ 
        alpha: false;
        background: @color; 
        foreground: #111;
        accent1: #111;
        accent2: #111;
    });
}

.bg.color1 { .color-theme(#0066ff ); }
.bg.color2 { .color-theme(#00e6da ); }
.bg.color3 { .color-theme(#00e417 ); }
.bg.color4 { .color-theme(#ffb700 ); }
.bg.color5 { .color-theme(#eb4a00 ); }
.bg.color6 { .color-theme(#e500ac ); }
.bg.color7 { .color-theme(#6200ff ); }

.bg.light1 { .color-theme(#cce0fe ); }
.bg.light2 { .color-theme(#c7f0ee ); }
.bg.light3 { .color-theme(#cef5d2 ); }
.bg.light4 { .color-theme(#feeab6 ); }
.bg.light5 { .color-theme(#ffdccc ); }
.bg.light6 { .color-theme(#fcccf0 ); }
.bg.light7 { .color-theme(#e2d5f7 ); }

.bg.success {

    .apply-theme({ 
        alpha: false;
        background: #D7FEEE; 
        foreground: darken( #D7FEEE, 80% );
        accent1: #111;
        accent2: #111;
    });

    /*border: solid 2px white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

    background: linear-gradient(to bottom, 
        fade( #D7FEEE, 100% ), 
        fade( #D7FEEE, 30% )
    );*/
}

.bg.error {
    box-shadow: none;
    border: none;
    .apply-theme({ 
        alpha: false;
        background: lighten( @cError, 20% ); 
        foreground: darken( @cError, 50% );
        accent1: #111;
        accent2: #111;
    });
}

.bg.warn { 
    box-shadow: none;
    border: none;
    .apply-theme({ 
        alpha: false;
        background: lighten( @cWarn, 20% ); 
        foreground: darken( @cWarn, 50% );
        accent1: #111;
        accent2: #111;
    });
}

.bg.info {
    box-shadow: none;
    border: none;
    .apply-theme({ 
        alpha: false;
        background: lighten( @cInfo, 50% ); 
        foreground: darken( @cInfo, 50% );
        accent1: #111;
        accent2: #111;
    });
}

.bg.gold {
    box-shadow: none;
    border: none;
    .apply-theme({ 
        alpha: false;
        background: lighten( #FFD700, 20% ); 
        foreground: darken( #FFD700, 50% );
        accent1: #111;
        accent2: #111;
    });
}

.bg.guide {

    &,&:hover {
        background-image: 
            radial-gradient(circle at 50% 50%, rgba(235, 226, 243, 0.9) 0%, rgba(235, 226, 243, 0) 50%),
            radial-gradient(circle at 50% 50%, rgba(227, 103, 174, 0.9) 0%, rgba(227, 103, 174, 0) 50%),
            radial-gradient(circle at 50% 50%, rgba(80, 60, 202, 0.9) 0%, rgba(80, 60, 202, 0) 50%),
            radial-gradient(circle at 50% 50%, rgba(173, 216, 255, 0.9) 0%, rgba(173, 216, 255, 0) 50%)
            !important;
        background-size: 150% 150% !important;
        animation: moveOrbs 5s infinite;
        
    }
    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: -50%;
    //     width: 200%;
    //     height: 100%;
    //     background: linear-gradient(
    //         to right,
    //         transparent,
    //         rgba(255, 255, 255, 0.1),
    //         transparent
    //     );
    //     transform: skewX(-15deg);
    // }

    @keyframes moveOrbs {
        0% {
            background-position: 0% 0%, 100% 0%, 0% 100%, 100% 100%;
        }
        25% {
            background-position: 50% 0%, 75% 25%, 25% 75%, 0% 50%;
        }
        50% {
            background-position: 100% 50%, 50% 100%, 0% 50%, 50% 0%;
        }
        75% {
            background-position: 50% 100%, 25% 75%, 75% 25%, 100% 50%;
        }
        100% {
            background-position: 100% 100%, 0% 100%, 100% 0%, 0% 0%;
        }
    }

    &.btn {
        position: relative;
        overflow: hidden;
        box-shadow: 
            0 4px 15px rgba(0, 0, 0, 0.1),
            inset 0 0 15px rgba(255, 255, 255, 0.2),
            0 0 0 0.5px rgba(255, 255, 255, 0.15);
        cursor: pointer;
        position: relative;
        color: rgba(0, 0, 0, 0.9) !important;
        overflow: hidden;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 50px;
    }
}

/*----------------------------------
- GRADIENT & IMG THEMES
----------------------------------*/

body {
        
    .apply-theme(@whiteTheme, @greyTheme);

    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     height: 100vh;
    //     z-index: -1;
    
    //     background: #FBFAFC !important;
    //     background-image: 
    //         radial-gradient(circle at -10% 0%, #E8E1EF, transparent 40%),
    //         radial-gradient(circle at 40% 0%, #FCE3E9, transparent 40%),
    //         radial-gradient(circle at 70% 0%, #FEF1DE, transparent 40%),
    //         radial-gradient(circle at 90% 0%, #FDEDE3, transparent 40%)
    //         !important;
    //     background-size: 100%;
    //     background-repeat: no-repeat;
    // }
}

.bg.img {
    
    .apply-theme({
        alpha: true;
        background: #000;
        foreground: fade(#fff, 60%);
        accent1: #fff;
        accent2: green;
    }, @whiteTheme);
}

.card:not(.bg), .card.bg.white {
    border: 1px solid var(--cLine);
}

.card.glass1 {
    color: fade(black, 100%) !important;
    background: fade(white, 80%);
    backdrop-filter: blur(20px) saturate(3);
    -webkit-backdrop-filter: blur(20px); // iOS
}

.card.glass2 {
    color: fade(black, 100%) !important;
    background: fade(white, 50%);
    backdrop-filter: blur(20px) saturate(2);
    -webkit-backdrop-filter: blur(20px); // iOS
}

.card.glass3 {
    color: fade(black, 100%) !important;
    background: fade(white, 30%);
    backdrop-filter: blur(20px) saturate(2);
    -webkit-backdrop-filter: blur(20px); // iOS
}

.card, 
img.img,
.Hero .input {
    border-radius: 1.5rem;
}

/*----------------------------------
- FOREGROUND THEMES
----------------------------------*/

// Color themes
.primary.fg { 
    .build-theme-fg(@c1, #fff); 
}

.fg {
    &.success { .build-theme-fg( @cSuccess ); }
    &.error { .build-theme-fg( @cError ); }
    &.warn { .build-theme-fg( @cWarn ); }

    &.gold { .build-theme-fg( #FFD700 - #222 ); }

    &.gradient {
        color: #B4C77C;
    }

    &.hologram {
        background: url(./img/hologram.png) center center;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
}