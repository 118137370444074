@import (reference) "@/client/assets/vars.less";

@base-hue: 10deg; // Adjust this value to change the hue of all colors
@pageHmargin: 5vw;
@pageTitleHeight: 5rem;

@leftbarWidth: 16rem;
@rightbarWidth: 20rem;

/*----------------------------------
- LAYOUT LEVEL 1
----------------------------------*/

.LogoText {
    text-decoration: none;

    > strong {
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 1.3em;
        color: var(--cTxtImportant);
        letter-spacing: -0.07em;
        transition: color 0.3s ease-out;
    }
}

.LogoSquare {
    height: 32px;
}

#topbar {

    // Causes issues wiht popover positionning
    position: sticky;
    top: 0;
    background: fade(#fff, 90%);
    z-index: 10;
    min-height: @pageTitleHeight;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

    nav > strong {
        // Align with menu buttons logo
        padding-left: 1.4em;
    }

    #userNav {
        padding-right: @pageHmargin;
    }
}


#leftbar nav > strong,
#rightBar div > strong {
    color: var(--cTxtDesc);
    margin-top: 1em;
    text-align: left;
}

#leftbar {
    
    position: sticky;
    top: 0;
    z-index: 10;

    height: 100vh;
    flex: 0 0 @leftbarWidth;
    width: @leftbarWidth;

    text-align: left;

    // Mobile menu open anmation
    transition: all .2s ease-out;

    #Logo {
        gap: 1em;
        height: @pageTitleHeight;
        padding-left: @spacing;
    }

    .btn {
        padding: 0 1em;
        gap: 1em;

        .label {
            color: #111;
        }
    }

    > nav > ul,
    > nav > strong,
    > footer {
        padding: 0 @spacing;
    }
}   

#contPage {
    min-height: 100vh;
    gap: 0;
    padding-bottom: 10vh;

    > header,
    > main {
        padding: 0 @pageHmargin;
    }
}

#page {
    flex: 1;
    position: relative;
}

#rightBar {
    flex: 0 0 @rightbarWidth;
}

.Hero {

    h1 {
        font-size: 3.2em;
        line-height: 1.5em;
    }

    h2 {
        font-size: 2.6em;
        line-height: 1.5em;
    }

    .input.text {
        background: white;
        border: solid 2px black;
        font-size: 1.5rem;
        padding-right: 0.3em;

        .btn {
            border-radius: inherit;
            background: black;
        }

        &,
        .btn {
            transition: all .1s ease-out;
        }

        &:hover,
        &.focus {

            border-color: @c1;
            box-shadow: 0 2px 10px fade(black, 10%);

            .btn {
                background: @c1;
            }
        }
    }
}

header {
    #pageTitle {
        h1 {
            font-size: 1.2em;
        }
    }
}

/*----------------------------------
- LAYOUT: LEVEL 2
----------------------------------*/

#Domains {

    > header {
        .input {
            height: 4em;
            padding: 0 2em;
            position: relative;
            max-width: 400px;
            box-shadow: none;
            background: none;

            .label {
                color: black;
            }

           &::before {
                position: absolute;
                top: -2px; left: -2px; right: -2px; bottom: -2px;

                z-index: 0;
                display: block;
                animation: button_btn-glow__T_YvK 5s linear infinite;
                background-image: linear-gradient(25deg, #00c2f84c, #ff0e2ebc 25.5%, #ffc229a2 30%, #9d00ffdb 49.5%, #0ec6c0c4 85.5%, #1eff7467);
                background-position: 100% 100%;
                background-size: 100% 200%;
                border-radius: 4em;
                filter: blur(20px);
                opacity: .55;
                transform-origin: center;
                transition: all .2s linear .1s;
                content: "";
            }

            &::after {
                content: ' ';
                display: block;
                position: absolute;
                z-index: 1;
                top: 0; right: 0; bottom: 0; left: 0;

                background: white;
                box-shadow: 0 5px 100px fade(#000, 10%);
                border-radius: 2.5em;
            }

            > * {
                z-index: 2;
            }

            @keyframes button_btn-glow__T_YvK {
                0% {
                    background-position: 0 100%
                }
            
                to {
                    background-position: 0 300%
                }
            }
        }
    }

    nav {

        position: sticky;
        top: 0;
        flex: 0 0 18rem;
        width: 18rem;
        
        .btn > i::before,
        .btn > i::after {
            transition: opacity 0.3s ease-out;
        }
    
        > strong {
            transition: all 0.3s ease-out;
            margin-left: 1em;
            font-size: 1.4em;
            font-weight: 700;
            color: #E5E5E5;
        }
    
        > ul {
            gap: 1vh;
        }
    
        > ul > li > .btn {
            padding: 0 1em;
        }
    
        .btn ~ ul.menu {
            top: 0em;
            left: 100%;
            margin: 0;
        }
    
        .menu > li {
    
            //position: static;
    
            > .menu {
                // Starting point for animation
                height: 0;
                overflow: hidden;
                transition: height .1s ease-out;
    
                // Reset submenu position
                position: static;
                left: 0;
                top: 0;
    
                // Submen distinction
                padding-left: 1em;
    
                /*height: calc(100vh - @leftbarHeight);
    
                // Override defalt submenu positionning
                top: @leftbarHeight !important; 
                left: 100%;
    
                background: fade(@cBgPage, 80%);
                backdrop-filter: blur(20px);*/
            }
    
            > .Current {
                margin-left: 4.5em;
            }
    
            .btn.active ~ .menu {
                height: 10em;
                height: auto;
                overflow: auto;
                //width: 300px;
            }
        }
    }
}

/*----------------------------------
- COMPONENT
----------------------------------*/

h2 {
    text-align: left;
    //color: var(--cTxtDesc);
}
.domainExamples {

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 10%;
        z-index: 2;
    }

    &::before {
        left: 0;
        background: linear-gradient(to right, fade(@cBgPage, 100%), fade(@cBgPage, 0%));
    }

    &::after {
        right: 0;
        background: linear-gradient(to left, fade(@cBgPage, 100%), fade(@cBgPage, 0%));
    }

    ul > li {

        position: relative;
        height: 130px;

        border-radius: @radius;
        border: solid 1px #e9E9E9;

        > a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1em;

            > img {
                height: 2.6em;
                margin-top: 1em;
            }
        }

        > a > div {
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            background: @cBgPage;
            text-shadow: none;
            transition: all 0.3s ease-out;
            opacity: 0;
            padding: 0 1em;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            > a > div {
                opacity: 1;
            }
        }
    }
}

.Domains {
    position: relative;
    gap: 2em;

    &.grid {
        grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    }

    &.row > article {
        flex: 0 0 18rem;
        min-width: 18rem;
    }

    > article {

        // Don't delay display as it could potentially decrease paint time and so seo
        //animation: fadeIn 1s forwards;
        position: relative;
        text-shadow: none;
        overflow: hidden;
        transition: all 0.3s ease-out;
        height: 15rem;

        > .contLogo {
            position: absolute;
            z-index: 0;
            top: -5px; left: -5px; right: -5px; bottom: -5px;
            display: flex;
            align-items: center;
            justify-content: center;

            > img {
    
                flex: 0 0 auto;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &:hover {
            > ul > li {
                opacity: 1 !important;
            }
        }

        > a {
            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
            z-index: 2;
            color: transparent !important;
            text-shadow: none;
        }

        > ul {

            > li:not(.Sponsored) {
                opacity: 0;
                transition: opacity 0.3s ease-out;
            }

            button.btn.s {
                &, i {
                    font-size: 1.6rem;
                    color: inherit;
                }

                z-index: 3;
                position: relative;
            }
        }

        .price {
            background: #F5F5F5;
            padding: 0.5em 1em;
            border-radius: @radius;
            color: #666;
        }

        .domainName {
            height: 32px;
        }
    }

    // #loadmore {
    //     box-shadow: 0 -2px 0 fade(@cBgPage, 40%);
    //     backdrop-filter: blur(10px);
    //     background: linear-gradient(to bottom, 
    //         fade(@cBgPage, 30%), 
    //         fade(@cBgPage, 100%)
    //     );
    //     position: absolute;
    //     left: -20px;
    //     right: -20px; bottom: -20px;

    //     padding: 2em 2em;

    //     display: flex;
    //     align-items: center;
    //     justify-content: center;

    //     .badge {
    //         background: fade(#fff, 25%);
    //         color: fade(#000, 60%);
    //     }

    //     > .card {
    //         opacity: 0;
    //         animation: appear 1s forwards;
    //         animation-duration: 2s;
        
    //         @keyframes appear {
    //             100% { opacity: 1; }
    //         }
    //     }
    // }
}

#modals > .modal {
    background: fade(black, 50%);
}

.Shortcuts .card {
    font-size: 1.6em;
    font-family: 'Poppins';
    padding: 2rem !important;
    line-height: 1.5em;
    text-decoration: none;

    /*&::after {
        height: 15px;
        border-radius: 8px;
        width: 80%;
        transition: all 0.3s ease-out;

        z-index: -2;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);

        background: url(@/client/assets/img/hologram-light.png);
        background-size: cover;
    }

    &:hover {
        &::after {
            width: 90%;
        }
    }*/

    &, strong {
        font-weight: 300;
    }
}

.reading.card {

    @hMargin: 3vw;

    padding: 2em @hMargin;

    figure,
    h2 {
        margin-left: -@hMargin;
        margin-right: -@hMargin;
    }

    figure {

        img {
            border-radius: 0;
        }

        figcaption {
            margin-left: @hMargin;
            margin-right: @hMargin;
        }
    }

    h2 {
        padding: 1em @hMargin 0 @hMargin;
        border-top: dotted 4px #e9E9E9;
    }
}

.rfm-marquee-container  {
    // Add left and right fadeout
    /*&::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 10%;
        z-index: 2;
    }

    &::before {
        left: 0;
        background: linear-gradient(to right, fade(@cBgPage, 100%), fade(@cBgPage, 0%));
    }

    &::after {
        right: 0;
        background: linear-gradient(to left, fade(@cBgPage, 100%), fade(@cBgPage, 0%));
    }*/
}

#loading {
    background: fade(@cBgPage, 80%);
    //backdrop-filter: blur(10px);
    z-index: 998;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease-out;

    &.display {
        opacity: 1;
        visibility: visible
    }
}

#searchPrompt {

    position: fixed;
    top: 0; left: 0; bottom: 0; right: 0;
    z-index: 1000;

    background: fade(black, 15%);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px); // iOS
    padding: 10vh 2vw;

}

#searching {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);
    background: fade(white, 20%);
    z-index: 5;

    padding: inherit; // Page margins
    padding-top: 150px; // Don't overflow the page title
}

.mgh-cover {
    margin-left: -@pageHmargin;
    margin-right: -@pageHmargin;
}

.pdh-page {
    padding-left: @pageHmargin;
    padding-right: @pageHmargin;
}

.skeleton {
    background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);

}

.ImgCard {

    position: relative;
    padding-top: 15rem !important;

    &::after {
        content: ' ';
        position: absolute;
        top: 0; right: 0; left: 0; bottom: 0;
        border-radius: inherit;
        display: block;

        z-index: 0;

        background: linear-gradient(to top, fade(black, 60%), fade(black, 10%));
    }

    > * {
        z-index: 1;
    }

    h1, h2 {
        text-shadow: 0 2px 10px fade(black, 100%);
    }

    .reading {
        color: fade(#fff, 90%);
        background: fade(#000, 80%);
        border-radius: @radius;
        padding: @spacing
    }
}

a.domainTld {
    display: inline-block;
    padding: 0px 10px;
    border-radius: @radius;
    box-shadow: 0 1px 2px fade(#000, 20%);
    text-decoration: none;
}

// Put at the end to override
@import "./mobile.less";